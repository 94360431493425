
<template>
    <div>
        <div>Test</div>
        <div class="text-info">{{ newurl }}</div>
        <!-- <iframe :src="url" width="100%" height="600px" type="application/pdf"></iframe> -->
        <!-- <iframe :src="base64Pdf" width="100%" height="600px" type="application/pdf"></iframe> -->
        <iframe :src="newurl" width="100%" height="600px" type="application/pdf"></iframe>
    </div>
</template>

<script>
import { ref,  onMounted } from 'vue'
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib'

export default {
    name: 'Test',
    components: { degrees, PDFDocument, rgb, StandardFonts },
    setup () {
        const base64Pdf = ref('')
        const pdfUrl = ref('')
        const url = ref('')
        const newurl = ref('')

        const convert2Url = async () => {
            const blob = await base64ToBlob(base64Pdf.value, 'application/pdf')
            return URL.createObjectURL(blob)

        }

        const base64ToBlob = (base64, mime) => {
            const byteCharacters = atob(base64)
            const byteNumbers = new Array(byteCharacters.length)

            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i)
            }

            const byteArray = new Uint8Array(byteNumbers)
            return new Blob([byteArray], { type: mime })
        }

        onMounted(async () => {
            url.value = 'https://app.signon.my:30002/signon/view/U2FsdGVkX1s1L2a3S4hILVsl2rpR0tgTs1L2a3S4huXUwAVgg9J3tPGxVdaL8aivU6F9DHGNtdqp1L2u3SNcMcPxpGsaupLWnGSmVumIJc86vx6DPSAdqAbmzNxS3QG5dpBdOs1L2a3S4hFSkyGRjEidf79mWz'
            const existingPdfBytes = await fetch(url.value, { credentials: 'include' }).then(res => res.arrayBuffer())
            const pdfDoc = await PDFDocument.load(existingPdfBytes)
            base64Pdf.value =  await pdfDoc.saveAsBase64({ dataUri: true })

            // const newURL = await convert2Url(base64Pdf.value)

            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)
            }
            else {
                newurl.value =  window.URL.createObjectURL(blob)
                console.info('newurl', newurl.value)
              
            }

        })

        return { 
            pdfUrl, url, newurl, base64Pdf
        }
    }
}
</script>

<style>

</style>